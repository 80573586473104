/* ----------------------------------- Fonts -------------------------------- */

/* ----- Gilroy ---- */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Gilroy/Gilroy-Light.woff2') format('woff2'),
       url('./Gilroy/Gilroy-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Gilroy/Gilroy-Medium.woff2') format('woff2'),
       url('./Gilroy/Gilroy-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./Gilroy/Gilroy-Bold.woff2') format('woff2'),
       url('./Gilroy/Gilroy-Bold.woff') format('woff');
}
